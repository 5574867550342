import { NextPage } from "next";
import { Button, Container, Icon, TextLink, Txt } from "anolis-ui";
import RobeHeading from "components/ui/RobeHeading";
import { x } from "@xstyled/emotion";
import Error404Icon from "components/icons/64/error-404.svg";
import Link from "next/link";
import Page from "components/layouts/Page";
import { ssgProps } from "../utils/ssgProps";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useTagManager } from "utils/useTagManager";

const Error404: NextPage = () => {
  const { formatMessage } = useIntl();
  useTagManager("404");

  return (
    <Page>
      <Container pt={{ _: "3rem", sm: "4rem" }} pb={{ _: "3rem", sm: "6rem" }}>
        <x.p color="gray" textAlign="center">
          <FormattedMessage id="404.errorType" defaultMessage="Error 404" />
        </x.p>

        <RobeHeading as="h1" mt="0.5rem">
          <FormattedMessage id="404.title" defaultMessage="The page cannot be found" />
        </RobeHeading>

        <x.div display="flex" flexDirection="column" alignItems="center" mt="3rem">
          <Icon svg={<Error404Icon />} fill="robe" />

          <Txt t="lead" textAlign="center" mt="3rem">
            <FormattedMessage id="404.mainDescription" defaultMessage="We apologise, but the page you are looking for cannot be found." />
          </Txt>

          <x.p mt="1rem" textAlign="center">
            <FormattedMessage id="404.secondaryDescription" defaultMessage="If you are somewhat sure it should be there, please" />
            {" "}
            <TextLink v="underlined" href="/static/contacts" target="_blank">
              {formatMessage(content.link)}
            </TextLink>.
          </x.p>

          <Link href="/" passHref>
            <Button s="sm" as="a" mt="2rem" px="2rem">
              {formatMessage(content.button)}
            </Button>
          </Link>
        </x.div>
      </Container>
    </Page>
  );
};

export default Error404;

export const getStaticProps = ssgProps();

const content = defineMessages({
  link: {
    id: "400.link",
    defaultMessage: "contact us"
  },
  button: {
    id: "400.button",
    defaultMessage: "Back to homepage"
  }
});
